
.modal-content-credits{
    display:flex;
    flex-direction: column;
    color:white;
    background-color: var(--main-color);
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: calc(30%); /* Could be more or less, depending on screen size */
    border-radius: 20px;
    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    /* margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    max-height: 90%;
    width: calc(100% / 2);
    height: 90%;
    margin-top: 2%;
    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards; */
    

}

.modal-content-credits input{
    /* background: none !important; */
    /* border-radius: 100px solid var(--main-link-button-color) ; */
    /* background: none !important; */

    
}

.close-inside-modal{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;

}

.close-inside-modal:hover{color:#999}

.modal-content-credits button{
    margin-top: 25px;
}

.message-success svg{
    font-size: 3rem;
    color: #299617;
}
.message-success span{
    font-size: 1.2rem;
}


@media (max-width: 1256px){
    .modal-content-credits{
      width: 90%;
    }
  }


