.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .paginationItem,.prev,
  .next{
    background-color: var(--second-color);
    color: white;
    padding: 10px 15px;
    border:none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: relative;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .prev,
  .next {
    padding: 10px 12px;
  }
  
  .paginationItem.active-pag {
    background-color: var(--main-link-button-color);
    pointer-events: none;
  }
  
  .prev.disabled,
  .next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  }