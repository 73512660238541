.section-breadcrumb {
    padding-bottom: 40px;
    padding-top: 40px;
}

.breadcrumb{
    justify-content: center;
}

.page-header-content{
    text-align: center;
}

.section-breadcrumb .page-header-content .page-header-inner .breadcrumb a:hover {
    color: var(--main-link-button-color);
}
.section-breadcrumb .page-header-content .page-header-inner .breadcrumb a {
    transition: all 0.3s ease;
}
