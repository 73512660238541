.general-breadcrumbs{
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 50px;
    background-color: var(--main-color);
}


.profile-my-account{
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    display: grid;
    place-content: center;

}

.profile-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.received-like{
    padding: 10px;
    font-size: 3rem;
    border-radius: 50%;
}
.left-side{
    margin-top: 30px;
}

.profile-photo{
    margin-top: 10px;
    width: 150px;
    height: 150px;
    border-radius:50%;
    object-fit: cover;
}

.profile-header-photo{
    max-width: 150px;
    height: 150px;
    width: 150px;
    transform: translate(20px, 20px);
    border-radius:4%;

}

.profile-header{
    display: flex;
    justify-content:space-between;
    border-radius: 6px;
    margin-bottom: 30px;
}

.featured-users{
    margin-top: 15px;
    border-radius: 6px;
    /* padding: 10px 0px; */
}

.featured-users a{
    color: white;
}

.each-user{
    padding-left: 15px;
}
.each-user:last-child{
    padding-bottom: 5px;
}

.featured-users a:hover{
    /* color: var(--first-color); */
}

.each-received-gift{
    width: 50%;
    display: flex;
    align-items: center;
    color: var(--main-color);
}


.featured-users-info{
    display: inline-grid;
    margin-left: 10px;
}

.profile-user-details{
    display: flex;
    flex-direction: column;
    margin-left: 45px;
    justify-content: center;

  }

  .make-gift:hover,.link-navigation:hover{
    color: #E6E6E6 !important;
  }

 


.profile-buttons{
    display: flex;
    align-items: center;
    width:50%;
    justify-content: center;
}



.profile-header-details{
    display: flex;
    justify-content: center;
}

.user-details{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
}

.user-details h3{
    width: 100%;
    font-size:1.25rem;
}

.each-detail{
    border-radius: 10px;
    padding: 7px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.photo-box{
    display:flex;
    margin-top: 15px;
    border-radius: 6px;
    padding: 10px 5px 10px 5px;

}


.each-image{
    width: 70px;
    height: 70px;
    margin: 0px 5px 0px 5px;
}

.featured-users-each-image{
    height: 50px;
    width: 50px;
    margin-top:10px;
}


.general-button{
    font-size:17px;
}

.credit-box{
    padding: 10px 30px;
    background-color: #2b2d43;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-all-buttons{
    padding-bottom: 20px;
}

.profile-member-buttons, .reactions{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.profile-all-buttons svg{
    margin-bottom: 2px;
}

.profile-member-buttons button{
    margin: 0 7px 0 7px;
}

.profile-member-buttons a{
    color: var(--main-link-button-color) ;
}

.user-details .item{
    margin: auto;
    padding: 4px 10px;
    width: 250px;
    cursor: pointer;
    border-radius:10px;
    display: flex;
    align-items: center;
    
}

.user-details .item .item-block span svg{
    margin-right: 10px;
}
.user-details .item .item-block span{
    text-transform: capitalize;
    margin-top: 2px;
}


.item-block{
    display: inline-grid;
}

.section-details{
    /* margin-top: 30px; */
    padding: 15px;
    border-radius: 6px
}

.member-details-img{
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    margin: 15px;
    position:relative;
}

.unlock-photo{
    /* font-family: Roboto,sans-serif; */
    font-size: 16px;
    color: #fff;
    text-align: center;
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unlock-photo span{
    bottom: 70px;
    position: absolute;
    right: 0;
    left: 0;
}
.member-img-all{
    margin-top: 25px;
    border-radius: 6px;
    justify-content: flex-start;
}

.div-set-profile{
    bottom: 5px;
    text-align: center;
}

.pad10{
    padding:10px;
}


@media (max-width: 992px)
{
    .photo-box{
        flex-direction: column;
        align-items: center;
    }
    .each-image{
        margin-top:10px;

    }
}
@media (max-width: 992px)
{
    .profile-buttons{
        width: 40%;
        display:block;
        margin: auto;
    }
    .profile-buttons button, .profile-buttons .main-btn{
        margin-top:10px;
    }
}

@media (max-width: 768px)
{
    .photo-box{
        flex-direction: row;
        justify-content: flex-start;
    }
    .each-image{
        margin: 0px 5px 0px 5px;

    }
}



@media (max-width: 640px)
{
    .profile-header{
        display: block;
    }

    .profile-buttons{
        width: 90%;
        margin-top:20px;
        padding-bottom:15px;
    }

    .profile-all-buttons{
        margin-top: 30px;
    }


}


@media (max-width: 600px)
{
    .user-details .item, .user-details .item-block{
        width: 100%;
    }
}
@media (max-width: 500px)
{
    .member-img-all{
        justify-content: center;
    }
    .each-received-gift{
        width: 100%;
        font-size: 1rem;
    }
}

@media (max-width: 380px)
{
    .profile-member-buttons{
        display: block;
        margin-top:10px;
    }
    .profile-member-buttons button{
        width:95%;
        margin-top:10px;
    }
    .each-received-gift{
        font-size: 0.7rem;
    }

}

@media (max-width: 1200px)
{
    .profile-buttons button, .profile-buttons .main-btn{
        width: 100%;
    }

}

