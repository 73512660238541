

.profile-member-img{
    width: 100%;
    height: 190px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.profile-member-container{
    margin: 25px;
    padding-bottom: 25px;
    width: 180px;
    cursor: pointer;
    color: white !important;
    text-decoration: none;
}

.profile-member-name:hover{
    background-color: var(--main-link-button-color);
    color: white;
}

.profile-member-name{
    text-align:center;
    border-radius: 10px;
    display:grid;
    padding: 5px;
}


img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
}

.container-search{
    width: 100%;
    padding: 0 0.75rem 0 0.75rem;
    border-radius: 6px;
    transition: all 0.3s ease;
    margin-bottom: 15px;
    /* max-height: 180px; */
}
.profile-members-search{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem 0 0.75rem;
 
}

.profile-members-search-input{
    outline: none;
    border: none;
    color: white;
    background-color: var(--second-color);
    padding:0;

}

.profile-members-search-elements{
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 0.375rem 0.75rem;
    outline: none;
    color: white;
    display:flex;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.profile-members-search-elements:hover{
    opacity: 0.8;
}

@keyframes rotateMenu {

    0% {
      transform: rotateX(-20deg)
    }
    70% {
      transform: rotateX(20deg)
    }
    100% {
      transform: rotateX(0deg)
    }
  }

.profile-members-search-filters{
    padding-bottom: 0.75rem;
    animation: rotateMenu 500ms ease-in-out forwards;
}

.profile-members-search-filters-elements{
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 0.375rem 0.75rem;
    outline: none;
    color: white;
    display:flex;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

}

.search-filter-elements{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem 0 0.75rem;
}

.filter-buttons{
    margin-top:10px;
    text-align: center;
}

.filter-buttons .main-btn{
    margin: 0 0.25rem 0 0.25rem;
}

.form-ceckbox{
    margin-right: 0.1rem;
    width: 15px;
    height: 15px;
}

.slider-parent{
    position:relative;
  }
  
.buble{
position:absolute;
}


@media (max-width: 480px){
    .profile-members-search{
        display: block;
        height: auto;
        text-align: center;
        padding: 1rem;
    }
    .profile-members-search-input{
        width: 100%;

    }
    .profile-members-search-elements{
        margin-bottom: 15px;
    }

}

@media (max-width: 992px){
    .search-filter-elements{
        display:block;

    }
    .search-filter-elements .slider-parent input{
        width: 100%;
    }
    .search-filter-elements .main-input{
        width: 100%;
        margin-bottom: 15px;
        padding: 2px 8px;
    }
    .search-filter-elements .slider-parent{
        margin-bottom: 30px;
    }
    .search-filter-elements .flex-align{
        justify-content: flex-start;
        margin-top: 10px;
        
    }
}
