html,
body {
    height: 100%;
    margin: 0;
    color: #fff !important;
    background-color: var(--main-lighter-color) !important;
    font-family: sans-serif;
}
head{
    background-color: var(--main-lighter-color);
}
#root {
    background-color: var(--main-lighter-color);
    min-height: 100%;
    position: relative;
}

header {
    background: var(--main-lighter-color);
    box-shadow: 0 0 10px rgb(255 255 255 / 10%);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 999;
    position: sticky;
    top: 0 !important;
}


footer {
    margin-top: auto;
    background-color: var(--main-color);
    padding-bottom: 5px !important;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: var(--main-color);
}

button{
    cursor: pointer;

}

input, select, textarea{
    outline: none;
    border-radius: 100px;
    background: var(--main-lighter-color);
    /* box-shadow: 0px 2px 2px 0px rgb(223 49 77 / 10%); */
    padding: 0.6rem 1rem;
    color: white;
    margin-bottom: 0.25rem;
}


input:hover, select:hover, textarea:hover{
    opacity: 0.90;
    transition: 0.1s;
}

.input-disabled{
    opacity: 0.5;
}


.link-bold{
    font-weight: bold;
}

.link-white-light,
.link-white-darker,
.link-white-button {
    color: var(--white-color);
    transition: 0.1s;

}

.link-white-light:hover {
    color: var(--main-lighter-color);
}

.link-white-darker:hover {
    color: var(--main-color);
}
.link-white-button:hover {
    color: var(--main-link-button-color);
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mg-top-10 {
    margin-top: 10px;
}
.mg-bottom-10 {
    margin-bottom: 10px;
}
.mg-left-10 {
    margin-left: 10px;
}
.mg-right-10 {
    margin-right: 10px;
}

.general-breadcrumbs{
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 50px;
    background-color: var(--main-color);
}

