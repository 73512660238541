#root{
  /* background-color:var(--main-lighter-color); */
  background-color:#C5A880;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* position: relative;
  min-height: 100vh; */
  /* justify-content: space-between; */
}

/* body, html{
  background-color:var(--main-lighter-color);
  height: 100%;
} */

/* .content{
  height:100%;
} */


:root{
  --color-hover-type-3: rgba(255, 255, 255, 0.1);

  /* --first-color: var(--main-link-button-color);
  --second-color: var(--main-lighter-color);
  --third-color: #090708;
  --main-color: var(--main-lighter-color);
  --last-color: #532E1C;
  --b-color:#532E1C; */
}

.p-color{
  color: #fff !important;
}
.t-color{
  color: var(--main-link-button-color) !important;
}
.pb-color{
  background-color: #fff;
}
.tb-color{
  background-color: var(--main-link-button-color) !important;
}
.vb-color{
  /* background: rgba(49, 19, 91, 0.95); */
  background: var(--main-lighter-color);
}
.bb-color{
  background: var(--main-color);
}

.gb-color{
  background: var(--main-lighter-color);
}


.b-color{
  color: green;
}

.tx-none{
  text-decoration: none !important;
}


.text-center{
  text-align: center;
}

.pos-abs{
  position: absolute;
}
.pos-rel{
  position: relative;
}

.a-link{
  text-decoration: none;
  font-weight: 700;
}

.bld{
  font-weight: 700;
}

.mg-top-25{
  margin-top:5% !important;
}
.mg-bottom-15{
  margin-bottom: 15px;
}

.mg-top-auto{
      margin-top: auto;
}


.w-100{width: 100%!important;}
.w-95{width: 90%!important;}
.w-40{width: 40%!important;}


.pad-0{padding:0px;}

.pad-20{padding:20px;}
.b-none{border:none;}

.mg-top-30px{margin-top: 30px;}

.flex-align,.footer-links{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-start{
  display: flex;
  align-items: center;
}

.flex-start-two{
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.flex-end-two{
  display:flex;
  align-items: center;
  justify-content: flex-end;
}

.main-round-image{
  border-radius:50%;
  object-fit: cover;
}

.hide{
  display: none;
}

.photo-blocked{
  filter: blur(10px);
}

a,.a-none{
  color: white;
  text-decoration: none !important;
}

.a-none-2{
  color: #532E1C;
  text-decoration: none !important;
}

.a-none:hover{
  color:white;
}

.ul{
  color:white;
}

.hover-type-3:hover{
  background-color: var(--color-hover-type-3);
}

::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--main-lighter-color);       /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);    
  /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

::placeholder{
  color:white;
}


/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--main-lighter-color);
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: var(--main-lighter-color);
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: var(--main-lighter-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}