.checkout-container{
    /* width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; */

    margin-top: 5%;
}

.content{
    /* margin-top: auto; */
}


.line-subtitle{
    width: 100%;
}


.product-detail, .product-benefits{
    display:flex;
    align-items: center;
}


.checkout-details{
    width: 600px;
    height: 500px;
    max-height: 100%;
    max-width: 100%;
    background-color: var(--main-color);;
    margin: auto;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-evenly;
    border-radius: 10px;
    padding: 0 30px;

    /* display:flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--second-color); */
}

.product-prices{
    display: flex;
    flex-direction: column;
}

.each-benefit{
    border-radius: 10px;
    padding: 7px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 5px;
    color: #E6E6E6;
}

.div-price{    
    height: 100%;
    width: 60%;
    display: flex;
    align-items: end;
    justify-content: end;
    flex-direction: column;
}

.payment-method-item{
    background: white;
    width: 50%;
    padding: 10px;
    border-radius: 10px;
    color: #E6E6E6;
    background-color: var(--main-lighter-color);
    display: flex;
    justify-content: space-between;
}

.payment-method-item svg{
    color: #2db300;
}

.checkout-provider{
    text-align: right;
}


.product-benefits{
    flex-wrap: wrap;
    /* width: 50%;
    justify-content: space-between;
    flex-direction: column; */
}

.checkout-container .pricing-image, .pricing-image-mobile{
    width: 70px;
    margin-right: 30px;

}

.pricing-image-mobile{
    display: none;
}

.checkout-header{
    width:100%;
}


@media (max-width: 768px){

    .checkout-details{
        width: 450px;
        height: 450px;
        margin-bottom: 25px;
    }
}
@media (max-width: 480px){

    .product-benefits{
        display: block;
    }
    .checkout-details{
        width: 280px;
        height: auto;
        margin-bottom: 20px;
        padding: 10px 15px;
    }
    .payment-method-item{
        width: 100%;
        opacity: 0.9;
    }

    .checkout-provider{
        padding: 10px 0;
    }
    .checkout-provider button{
        width: 100%;
        border-radius: 10px;
    }

    .each-benefit{
        opacity: 0.9;
    }
    .product-detail{
        padding: 10px 0;
        justify-content: center;
        
    }

    .pricing-image-mobile{
        display: block; 
        margin-right: 0;
    }
    .product-detail .pricing-image{
        display: none;
    }
    .checkout-container .pricing-image{
        margin: 10px auto;
        
    
    }
    
}