.login-form{
    max-width: 420px;
    padding: 40px 30px;
    border-radius: 50px;
    box-shadow: 0 2px 100px 0 #ffc107c4;
    padding: 40px;
    text-align: center;
    max-width: 505px;
    margin: 0 auto 15px;
    
}

.form-login-item{
    margin-top: 10px;
}
/* .form-login-item input{
    border: 1px solid rgba(223, 49, 77, 0.3);
    outline: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 100px;
    background: var(--main-lighter-color);
    box-shadow: 0px 2px 2px 0px rgb(223 49 77 / 10%);
    padding: 0.6rem 1rem;
    color: white;
  } */

  .login-extra-row{
      margin-top: 20px;
  }

  .extra-group{
      margin-top: 20px;
      margin-bottom: 15px;
      font-size: 1rem;
  }
  .login-extra-row a{
      color: white;
      font-weight: 700;
      text-decoration: none;
  }

  .login-extra-row a:hover{
      color: var(--main-link-button-color);
      transition: all 0.3s ease;
  }

.howver:hover{
    background-color: var(--main-link-button-color);
    color: #fff;
    box-shadow: 3px 3px 0 currentColor;
    transition: all 0.3s ease;
  }

.howver-white:hover{
    background-color: white;
    color: var(--main-link-button-color);
    box-shadow: 3px 3px 0 currentColor;
    transition: all 0.3s ease;
  }

  