.modal-image{
    animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    position: fixed; 
    z-index: 1000; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: hidden;
    background-color: rgba(0,0,0,0.9); 
    
}

.modal-content-image{
    margin: auto;
    display: block;
    width: 80%;
    /* max-width: 700px; */
    max-height: 90%;
    width: calc(100% / 2);
    height: 90%;
    margin-top: 2%;
    animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

}

.modal-content-image img{
    object-fit: contain;
}

.modal-content-image2{
    display: block;
    width: auto;
    max-height: 100%
}
.modal-content-image3{

    display:flex;
    color:black;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 300px; /* Could be more or less, depending on screen size */
}

.modal-body {
    display:flex;
}


@keyframes scaleUp {
0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
    }
    100% {
    transform:scale(1) translateY(0px);
    opacity:1;
    }
}

.close {
    color: white;
    position: absolute;
    z-index: 1001;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
  }

  .close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1256px){
    .modal-content-image{
      width: 90%;
      height: 100%;
    }
  }


